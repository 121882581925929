.vehicle-class {
  font-size: x-large;
  font-weight: 700;
}

.container {
  padding: 10px 50px 50px;

  .card {
    width: 900px;
    border-radius: 10px;
    background-color: #fbfbfb;
    padding: 1px 40px;

    .result {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        span {
          // color: #979797;
          margin-bottom: 10px;

          .clock {
            margin-left: 10px;
          }
        }

        .travellers {
          color: black;
        }
      }

      .price {
        font-size: xx-large;
        font-weight: 900;
      }
    }
  }
}

.alert {
  color: #1890ff;
}

.btn-right {
  display: flex;
  justify-content: flex-end;
  
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px;

    .card {
      width: 100%;
      padding: 20px;
  
      .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .details {
          .vehicle-class {
            padding: 0 10px;
          }

          span {
            padding: 0 10px;
          }

        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .container {
    .card {
      width: 100%;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;