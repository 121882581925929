.reservation-container {
  margin-bottom: 20px;

  .ant-card {
    margin-bottom: 23px;
  }

  .ant-card-head-title {
    color: rgb(0 0 0 / 85%);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .reservation-alert {
    padding: 9px 16px;
    border-radius: 2px;
    border: 1px solid #ffccc7;
    background: #fff1f0;
    min-height: 93px;

    .ant-alert-message {
      color: rgb(0 0 0 / 85%);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .ant-alert-description {
      color: rgb(0 0 0 / 85%);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .reservation-trip-details {
    .ant-card-extra {
      a {
        color: #1890ff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .reservation-trip-card-content {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      h2 {
        color: rgb(0 0 0 / 80%);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      p {
        color: rgb(0 0 0 / 65%);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
      }
    }
  }

  .reservation-passenger-details {
    .ant-card-body {
      padding: 0;

      .ant-table-thead > tr > th {
        color: rgb(0 0 0 / 80%);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }

      td {
        color: #111;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.001px;
        text-transform: capitalize;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
    }
  }

  .reservation-payment-details {
    .reservation-payment-card-content {
      border-radius: 2px;
      border: 2px solid #f0f0f0;
      background: #fff;
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2,
        p {
          padding: 0;
          margin: 0;
        }

        h2 {
          color: rgb(0 0 0 / 85%);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }

        p {
          color: rgb(0 0 0 / 85%);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        span {
          border-radius: 100px;
          background: #1890ff;
          display: flex;
          padding: 0 8px;
          justify-content: center;
          align-items: center;

          p {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .payment-box-shadow {
        box-shadow: 0 -1px 0 0 #f0f0f0 inset;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;