.login-main {
  height: 100vh;
  position: relative;
  background-color: #fff;
}

.login-top-icon {
  position: absolute;
  top: 2rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 15px #f8f8f8;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    transform: translateY(-2px);
  }
}

.login-bottom-icon {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 15px #888;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    transform: translateY(-2px);
  }
}

.login-content {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
}

.login-form {
  margin-inline: auto;
  width: 550px;
  padding: 2rem;
}

.login-input {
  padding: 0.8rem;
}

.login-footer {
  background-color: #fff;
  text-align: center;
}

.login-alert {
  text-align: center;
}

.login-form-remember-me {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .login-form {
    width: 350px;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;