.seat-box {
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 500;
  outline: none;

  .steering {
    font-size: 38px;
  }
}

.seat-map-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #f5f5f5;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.seatbox-dropdown .ant-dropdown-menu-item:hover{
  background-color: transparent !important;

}

.seat-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.seat-row-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.seat-selector {
  .seat-selector-color-display-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 40px;

    .seat-display-color-box-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .seat-display-color-box {
        border-radius: 4px;
        width: 14px;
        height: 14px;
      }

      p {
        text-transform: capitalize;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;