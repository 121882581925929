.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-right: 10px;
    text-transform: capitalize;
  }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;