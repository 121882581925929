.schedule {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 980px;
  border: 0.5px solid #1890ff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;

  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    p {
      font-size: 90%;
    }

    .button {
      font-weight: 600;
      color: #1890ff;
    }
  }
}

@media screen and (max-width: 767px) {
  .schedule {
    display: flex;
    flex-direction: column;
    width: 100%;

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .schedule {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 700px;

    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;