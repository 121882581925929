.trip-seat-map-container {
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trip-seat-map-container.check-in-modal {
  padding: 0 !important;

  .trip-seat-map-main {
    .seat-selector {
      .seat-selector-color-display-container {
        margin-top: 0 !important;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;