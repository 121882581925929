.search-trip-container {
  .header-title {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .aci {
    margin-bottom: 0;
  }

  .form-container-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin: auto;

    .input {
      width: 100%;

      .date-picker {
        width: 500px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .search-trip-container {
    .header-title {
      text-align: center;
      margin-bottom: 50px;
      margin-top: 20px;
    }

    .aci {
      margin-bottom: 0;
    }

    .form-container-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      width: 250px;

      .input {
        width: 100%;

        .date-picker {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .form-container {
    width: 50%;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;