.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;

  h1 {
    font-weight: bold;
  }

  img {
    width: 125px;
    height: auto;
    margin-top: 2rem;
  }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;