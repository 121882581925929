.filters {
  width: 200px;
  border: 1px solid #d3d3d3;
  margin-top: 40px;

  .ant-select-selection-placeholder {
    color: black;
  }

  .ant-picker-input input::placeholder {
    color: black;
  }

  .ant-select-arrow .anticon {
    color: black;
  }

  .ant-picker-input .anticon {
    color: #333;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;