.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .collapse {
    width: 980px;

    .proceed {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 50px;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;