.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .collapse {
    width: 882px;

    .form-container-card {
      padding: 1px 25px;

      .form {
        width: 100%;

        .checkbox {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .proceed {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 882px;
      margin-bottom: 50px;
    }

    .next {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 30px 1px;
    }

    .phone-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .group1 {
        margin-right: 10px;
      }

      .flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 40px;

        .green {
          height: 100%;
          width: 20px;
          background-color: green;
        }

        .white {
          height: 100%;
          width: 20px;
          background-color: white;
        }
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;