.capsule {
  padding: 8px 14px;
  border: 1px solid #1890ff;
  border-radius: 15px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 1.6%);
}

.card {
  width: 100%;

  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .passenger-search {
      width: 250px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    .search-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;

      .passenger-search {
        width: 80%;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;