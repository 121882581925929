.waybill-container {
    width: 100%;
}

.waybill-extra-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .waybill-search {
        width: 250px;
    }
}

@media screen and (max-width: 767px) {
    .waybill-extra-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;

        .waybill-search {
            width: 100%;
        }
    }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;