.document-container {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    h1 {
      font-weight: bold;
    }

    img {
      width: 125px;
      height: auto;
    }
  }

  .descriptions {
    display: flex;
    flex-direction: column;
  }

  .hide {
    display: none;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;