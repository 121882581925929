.main-container {
  background-color: #f5f5f5;
}

.sider-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
  box-shadow: 0 2px 8px rgba(0 0 0 / 15%);
  overflow: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
    border-radius: 3px;
  }

  .sider-menu {
    // margin-top: 20px;
    background-color: #fafafa;
    height: calc(100vh - 200px) !important;
    overflow-x: hidden !important;
  }

  .sider-menu::-webkit-scrollbar {
    width: 4px;
  }

  .sider-menu::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 20%);
    border-radius: 10px;
  }

  .sider-menu::-webkit-scrollbar-track {
    background: transparent;
  }

  .sider-menu.ant-menu-inline-collapsed{
    height: calc(100vh - 140px) !important; 
  }

  .sidebar-links {
    text-decoration: none;
    color: black;
  }

  .card-div {
    width: 100%;
    padding: 15px;

    .user-card {
      width: 100%;
      box-shadow: 1px 1px 4px 0 rgb(131 137 160 / 12%);

      .account-info-display {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name-display {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 8px;
        }
      }
    }
  }
}

.content-box {
  background-color: #fff;
  height: 100vh;

  .header {
    background-color: #fff;
  }

  .search-icon {
    margin-right: 10px;
  }

  .outlet {
    padding: 0 2%;
    overflow-y: auto;
    height: 100vh;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;