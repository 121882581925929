@import '~antd/dist/antd';

.fw {
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-50 {
  margin-top: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-100 {
  margin-top: 100px;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;

  .space-between {
    justify-content: space-between;
  }

  &.space-between {
    justify-content: space-between;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;