.user-page-container {
  width: 100%;

  .extra-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .user-search {
      width: 250px;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 768px) {
  .user-page-container {
    width: 100%;

    .extra-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;

      .user-search {
        width: 100%;
      }
    }
  }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;