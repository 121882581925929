
.passenger-ticket-container {
  padding: 2px;
  
  // This is ths estimated conversion for 58mm which is the size of the printing paper
  width: 219.2126px;
  margin: auto;
  font-size: 6.08px;
  text-align: center;
  background-color: #fff;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
   
    img {
      width: 68px;
      height: 36px;
    }
  }

  .qr-code {
    padding: 10px;
  }

  .info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    .info-text {
      font-size: 10px;
      font-weight: 600;
      text-align: start;
    }

    .info-p {
      font-size: 10px;
      font-weight: 500;
      text-align: end;
    }
  }

  .info-line {
    font-size: 7px;
    font-weight: 500;
    margin-top: 20px;
    
    .info-line-tel {
      margin-top: 5px;
    }
  }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;