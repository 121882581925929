.summary-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 50%;
  height: 12%;
  border: 1px solid #d4d4d4;
  border-radius: 5px;

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 10px;
    gap: 20px;

    p {
      color: grey;
      font-weight: bold;
    }

    h1 {
      color: #273c75;
      font-weight: bolder;
      font-size: 160%;
    }
  }
}

.top-section {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 15px;

  .summary-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .summary {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;

    .summary-group {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 50%;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .summary-base {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    padding-right: 10px;
    width: 50%;
  }
}

.mid-section {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  .left {
    width: 60%;
    display: flex;
    gap: 20px;
  }

  .right {
    width: 40%;
    display: flex;
    gap: 20px;
  }
}

.show-transactions {
  border: none;
  background: none;
  padding: none;
  color: #2600ff;
  font-weight: bold;
}

.show-transactions:hover {
  border: none;
  background: none;
  padding: none;
  color: #4690ff;
  transition: ease-in-out;
  transition-duration: 300ms;
  scale: 1.2;
  cursor: pointer;
}

.table {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-bottom: 40px;
  overflow-y: scroll;
  max-height: 500px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.waybill-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: bolder;
      font-size: large;
    }
  }
}

.waybillcard-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  padding-top: 5px;

  .waybillcard-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    p {
      font-weight: bold;
    }
  }

  .waybillcard-item-right {
    p {
      font-weight: bold;
    }
  }
}

.statistics-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: bolder;
      font-size: large;
    }
  }
}

.statisticscard-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;

  .statisticscard-item-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2px;

    p {
      font-weight: bold;
    }
  }
}

.activities-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: bolder;
      font-size: large;
    }
  }
}

.tripscard-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;

  .tripscard-item-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 10px;

    p {
      font-weight: bold;
    }

    span {
      font-size: small;
    }
  }
}

.activitiescard-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 5px;

  .activitiescard-item-right {
    p {
      font-weight: bold;
    }

    .blue {
      color: #184aab;
    }

    .green {
      color: #12c26e;
    }
  }

  .activitiescard-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .details {
      display: flex;
      flex-direction: column;
      line-height: 10px;
      align-items: flex-start;
      justify-content: center;

      p {
        font-weight: bold;
      }
    }
  }
}

.filter {
  padding: 1px 2px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 1.6%);
  cursor: pointer;
  width: 160px;

  .ant-select-selection-placeholder {
    color: black;
  }

  .ant-select-arrow .anticon {
    color: black;
  }
}

@media screen and (max-width: 992px) {
  .summary-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 10%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 10px;
      gap: 20px;

      p {
        color: grey;
        font-weight: bold;
      }

      h1 {
        color: #273c75;
        font-weight: bolder;
        font-size: 160%;
      }
    }
  }

  .top-section {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 15px;
    width: 100%;

    .summary-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .summary {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      align-items: center;
      justify-content: center;

      .summary-group {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;
        align-items: center;
        justify-content: center;
      }
    }

    .summary-base {
      display: flex;
      flex-direction: column;
      padding-right: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .summary-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 10%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 10px;
      gap: 20px;

      p {
        color: grey;
        font-weight: bold;
      }

      h1 {
        color: #273c75;
        font-weight: bolder;
        font-size: 160%;
      }
    }
  }

  .top-section {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 15px;
    width: 100%;

    .summary-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .summary {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      align-items: center;
      justify-content: center;

      .summary-group {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mid-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .left {
      width: 100%;
      display: flex;
      gap: 20px;
    }

    .right {
      width: 100%;
      display: flex;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .mid-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .left {
      width: 100%;
      display: flex;
      gap: 20px;
    }

    .right {
      width: 100%;
      display: flex;
      gap: 20px;
    }
  }

  .waybillcard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    padding-top: 5px;

    .waybillcard-item-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      p {
        font-weight: bold;
      }
    }

    .waybillcard-item-right {
      p {
        font-weight: bold;
      }
    }
  }

  .tripscard-item-right {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .top-section {
    .summary-base {
      display: flex;
      flex-direction: row;
      padding-right: 0;
      width: 100%;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;