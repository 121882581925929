.schedule-container-card {
  background: #f4f5f7;
}

@media screen and (max-width: 768px) {
  .schedule-container {
    width: 309px;
  }
}

.schedule-container {
  margin-inline: auto;
}

.flex {
  display: flex;
  align-items: center;
}

.pb-7 {
  padding-bottom: 7px;
}

.px-7 {
  padding-inline: 7px;
}

.lead {
  color: #8c8c8c;
}

.fs-small {
  font-size: 14px;
}

.error-alert {
  color: red;
  text-align: center;
}

.filter-card {
  display: flex;
  justify-content: space-between;

  .filter-card-btn {
    display: flex;
    gap: 20px;
  }
}

.filter-card-btn-alt {
  //   max-width: 0;
  padding-left: 0 !important;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .filter-card {
    display: flex;
    flex-direction: column;

    .filter-card-btn {
      display: flex;
      flex-direction: column;
      width: 190px;
      padding-left: 22px;
    }
  }
}

.capsule {
  padding: 8px 14px;
  border: 1px solid grey;
  border-radius: 15px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 1.6%);
}

.select-form {
  border: 1px solid #f4f1f1;

  .ant-select-selection-placeholder {
    color: black;
  }

  .ant-picker-input input::placeholder {
    color: black;
  }

  .ant-select-arrow .anticon {
    color: black;
  }

  .ant-picker-input .anticon {
    color: #333;
  }
}

.input {
  border: none;
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;