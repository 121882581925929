.waybill-document {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    height: auto;

    img {
      width: 120px;
      height: auto;
    }
  }

  .header {
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      text-transform: uppercase;
      font-size: large;
      font-weight: bold;
    }

    span {
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 50%;

        h3 {
          font-weight: bold;
        }
      }
    }

    .signature-line {
      width: 300px;
      border-bottom: 1px #ccc solid;
      margin-bottom: 15px;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;