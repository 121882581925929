.payment-confirm-container {
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 80px;

  .left {
    width: 40%;

    .left-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 18px 10px 12px;
      border: 1px solid #1890ff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .options-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .options {
        margin-top: 10px;
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .payment-option {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          border: 0.5px solid #979797;
          border-right: none;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 15px;
          width: 100%;
          font-weight: 600;
        }
      }

      .radios {
        margin-top: 10px;
        width: 10%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .radio {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 0.5px solid #979797;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-left: none;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 5px;
          width: 100%;
        }
      }
    }
  }

  .right {
    width: 60%;

    .issue {
      width: 100%;
      margin-top: 50px;
      height: 61px;
    }

    .right-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 18px 10px 12px;
      border: 1px solid #1890ff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .right-bottom-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 18px;
      border: 1px solid #1890ff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .options-container {
      display: flex;
      flex-direction: column;

      .options {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #979797;
        border-left: 0.5px solid #979797;
        border-right: 0.5px solid #979797;
        background: linear-gradient(to right, #fafbfc 0%, #fafbfc 30%, white 30%, white 100%);

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 15px;
          width: 30%;
          font-weight: 600;
          text-align: left;
        }

        .value {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 15px;
          width: 70%;
          font-weight: 400;
          text-align: right;

          p {
            text-align: right;
          }

          p:first-child {
            color: #1890ff;
          }

          span {
            color: #1890ff;
          }
        }
      }

      .options:last-child {
        border-bottom: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .payment-confirm-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .left {
      width: 100%;

      .left-header {
        display: flex;
        flex-direction: column;
      }

      .options {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;

        .payment-option {
          width: 100%;
          padding: 0;
        }
      }


      .radios {
        margin-top: 0;

        .radio-cash {
          margin-bottom: 0;
        }

        .radio-transfer {
          height: 76px;
        }
      }
    }

    .right {
      width: 100%;

      .issue {
        width: 100%;
        padding-right: 10px;
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .radio-cash {
    margin-bottom: 0;
  }

  .radio-transfer {
    height: 76px;
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;