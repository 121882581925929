.error-alert {
  margin-bottom: 20px;
}

.time-picker {
  width: 100%;
}

.week {
  width: 100%;
}

.days {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .withlab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.form-item {
  width: 230px;
}

.optional {
  color: gray;
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;