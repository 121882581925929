.search-result-container {
  padding: 10%;

  .result-header {
    color: #111;
  }

  .result-sub-header {
    color: #1890FF;
  }
}
@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;