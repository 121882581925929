.passenger-manifest {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    height: auto;

    img {
      width: 80px;
      height: auto;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 50%;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;