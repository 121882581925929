.ant-card-extra {
  margin-left: 0;
}

.card {
  width: 100%;

  .search-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .reservation-search {
      width: 250px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    .search-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;

      .reservation-search {
        width: 80%;
      }
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;