.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: auto;
  justify-content: center;

  p {
    font-size: 80%;
  }

  .payment-type {
    display: flex;
    flex-direction: row;
    padding: 5px 15px;
    align-items: center;
    border: 0.5px solid #1890ff;
    border-radius: 5px;
    width: 100%;
    margin: 10px 1px;
    gap: 15px;

    .details {
      display: flex;
      flex-direction: column;
      gap: 0;

      .title {
        font-weight: 600;
        font-size: 100%;
        margin-bottom: -2px;
      }
    }
  }

  .button {
    margin: 10px;
    width: 400px;
  }

  .form-div {
    width: 400px;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 10px;
    }

    .form {
      width: 100%;

      .email-field {
        width: 266px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .success-container {
    width: 100%;
    padding: 10px;

    .payment-type {
      width: 100%;
    }

    .button {
      width: 100%;
    }
  }
}

@primary-color: #1890FF;@font-family: 'Montserrat', sans-serif;